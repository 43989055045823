<template>
  <div>
    <h4>Executive Reproduction</h4>
    <div class="small mb-3">Printing, Paper</div>
    <img src="@/assets/male1&yuriko.jpg" class="img-fluid" />
    <img src="@/assets/male2&merkel.jpg" class="img-fluid" />
    <img src="@/assets/male3&harris.jpg" class="img-fluid" />
    <hr />
    <div class="mb-3">AIとジェンダーバイアス</div>
    <p class="small">
      近年、急速に技術開発と実用化が進んでいるAIですが、その開発には写真やテキストなどの大量のデータが必要とされます。その学習データが現実社会の差別や偏見を含んでいる場合、AIもその差別や偏見を学習してしまいます。実際、既に実用化されているAI製品には、ジェンダーバイアスを含むものがあることが分かっています。
    </p>
    <p class="small">
      本作品では、Amazonの画像認識システム*を用いて、フリー素材の男性3名と、政界のリーダーである女性3名の画像**を判定しました。男性は高確率で「Executive（経営幹部、重役）」とみなされる一方、女性は男性よりExecutiveの確率が低いか、もしくはExecutiveだとみなされませんでした。さらに、女性は「少女」「子供」だと判定されました。
    </p>
    <p class="small">
      実際、本作品で使用したAmazonの画像認識システムだけでなく、世界中で広く利用されているGoogleやMicrosoftの認識システムについても、こうしたジェンダーバイアスを含んでいることが分かっています。一般的に、男性に対しては社会的地位が高い判定がされる一方、女性に対しては社会的地位の低い判定がなされます。
    </p>
    <p class="small">
      現実世界で使われているAIが、社会に浸透しているステレオタイプや偏見を学習し、反映していることは数多く報告されています。これは画像認識技術に限らず、自然言語生成などのAIに関する他の分野でも同様です。このようなAIを使うことは、意図しない差別や偏見の再生産や増幅、さらには新しい価値観の形成を困難にすることにつながります。
    </p>
    <p class="small">
      AI技術の発展の裏には、見落とされがちな差別や偏見の再生産の危険が常にあります。この再生産を食い止め、女性もExecutiveだとみなされる未来のために、私たちは望ましいAIとは何かを議論する必要があります。
    </p>
    <p class="small">
      <span class="small" style="font-size: 11px">
        * Amazon Rekognition Custom Labels **
        女性の顔はそれぞれ、小池百合子（東京都知事）、カマラ・ハリス（アメリカ副大統領）、アンゲラ・メルケル（ドイツ首相）の3名。男性の写真の顔部分のみ、女性の顔に入れ替えた。背景や服装は男性と同一。</span
      >
    </p>
    <hr />
    <div class="mb-2">Gender Bias in AI</div>
    <p class="small">
      In recent years, AI(Artificial Intelligence) has been developed and put to
      practical use rapidly, and its development requires a large amount of
      data, such as images and text. If the training data contains
      discrimination and prejudice from the real world, the AI will also learn
      that discrimination and prejudice. In fact, it has been found that some AI
      products already in practical use contain gender bias.
    </p>
    <p class="small">
      In this work, I used the Amazon image recognition system* to predict the
      free images of three men and three women** who are political leaders. The
      men were highly likely to be considered "executives," compared to the
      women who sometimes weren’t considered executives at all. In addition,
      women were judged to be "girls" and "children.
    </p>
    <p class="small">
      In fact, not only the Amazon image recognition system was used in this
      work. Google and Microsoft recognition systems, which are widely used
      around the world, have also been found to contain such gender bias. In
      general, men are judged to have a higher social status compared to women.
    </p>
    <p class="small">
      It has been widely reported that AI used in a society learns and reflects
      the stereotypes and prejudices prevalent in said society. You can find
      similar cases not only in the image recognition technology, but also in
      other areas, such as natural language generation. By using such an AI, you
      can reproduce and amplify the discrimination and prejudice
      unintentionally, which hinders the formulation of new values.
    </p>
    <p class="small">
      Through the development of AI technology, the overlooked discrimination
      and prejudice can be reproduced. In order to stop this reproduction, and
      to realize the future where women are also considered executives, we need
      to discuss what desirable AI is.
    </p>
    <p class="small">
      <span class="small" style="font-size: 11px">
        * Amazon Rekognition Custom Labels ** The females are Yuriko Koike
        (Governor of Tokyo), Kamala Harris (Vice President of the United
        States), and Angela Merkel (Chancellor of Germany), respectively. The
        facial parts of the men's photos were replaced with the women's faces.
        The background and clothing are identical to the men.</span
      >
    </p>
  </div>
</template>
